import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CompleteOrder from '../components/cart/complete-order'

const Calculator = props => (
  <Layout location={props.location}>
    <SEO
      title="Submission Completed"
      keywords={[
        `novartis`,
        `medgrocer`,
        `pulse care`,
        `entresto`,
        `sacubitril-valsartan`,
      ]}
    />
    <CompleteOrder />
  </Layout>
)

export default Calculator
