import React, { Component } from "react"
import { Link } from "gatsby"
import ContentFold from "../page-content/content-fold"
import { withTranslation } from "react-i18next"

import Message from "../elements/Message"

import { Formik, Form } from "formik"
import NewFormDate from "../forms/NewFormDate"

class CompleteOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      starHovered: -1,
      stars: [1, 2, 3, 4, 5],
    }
  }

  handleMouseEnter = event => {
    var starNumber = parseInt(event.target.id) + 1
    this.state.stars.forEach((star, index) =>
      this.setState(props => {
        return { starHovered: starNumber }
      })
    )
  }

  handleMouseLeave = event => {
    this.state.stars.forEach((star, index) =>
      this.setState(props => {
        return { starHovered: -1 }
      })
    )
  }

  selectStar = event => {}

  render() {
    const { t } = this.props
    return (
      <ContentFold title={t("Thank you for ordering!")} isCentered>
        <p className="content">
          {t(
            "Please expect an SMS verifying your order by the next working day."
          )}
        </p>
        <p className="content">
          {t(
            "If you don't receive the SMS, please contact us at 0917 848 2088 as our system automatically cancels unconfirmed orders within 12 hours."
          )}
        </p>
        <p>
          <Message color="primary">
            {t(
              "The Pulse Care Program only provides Sacubitril-Valsartan. If you have other medicines, you may order at "
            )}
            <a
              href="https://medgrocer.com"
              target="blank"
              rel="noreferrer noopener"
            >
              medgrocer.com.
            </a>
          </Message>
        </p>
        {/* <div className="notification">
          <p className="has-text-centered">
            How strongly would you recommend the MedGrocer experience?
          </p>
          <br />
          <div className={classNames('has-text-centered', styles.rowRating)}>
            {this.state.stars.map((star, index) => (
              <span
                className={classNames(
                  'has-text-centered',
                  {
                    'has-text-warning': this.state.starHovered > index,
                  },
                  { 'has-text-light': this.state.starHovered <= index }
                )}
                onClick={this.selectStar}
                key={star}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                id={index}
              >
                {this.state.starHovered <= index ? (
                  'O'
                ) : (
                  <FontAwesomeIcon icon={faStar} />
                )}
              </span>
            ))}
          </div>
        </div> */}
        <br />
        <div className="has-text-centered mt-2">
          <Link
            to="/patient"
            className="button is-outlined is-primary is-medium mb-2"
          >
            {t("Back to Home")}
          </Link>
          <a
            href="https://medgrocer.com"
            target="_blank"
            rel="noreferrer noopener"
            className="ml-1 ml-0-mobile button is-primary is-medium"
          >
            Order at medgrocer.com
          </a>
        </div>
        <Formik
          initialValues={{
            testDateOptions: {
              month: {
                label: null,
                value: null,
              },
              date: {
                label: null,
                value: null,
              },
              year: {
                label: null,
                value: null,
              },
            }
          }}
        >
          { ({ values, setFieldValue, handleChange }) => {
            return(
              <Form>
                <NewFormDate
                  name="testDateOptions"
                  label="Set Date Options"
                  helper="help !!!!"
                  values={values.testDateOptions}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  config={{
                    birthday: true
                  }}
                />
              </Form>
            )
          }}
        </Formik>
      </ContentFold>
    )
  }
}

export default withTranslation()(CompleteOrder)
